.main2{
background-color: #212429;
}
.welcome-container{
    background-color: rgb(20, 20, 20);
    width: 60%;
    margin-left: 20%;
    color: white;
    border-radius: 10px;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-align: center;

}