.login-button {
  background-color: rgb(255, 255, 255, 0.9);
  border: none;
  border-radius: 10px;
  font-size: 1.2rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 700;
  padding: 0.5rem 1.8rem;
  margin: 3rem;
  margin-top: 4rem;
}
.modal-header,
.modal-body {
  background-color: rgba(1, 1, 1, 0.95);
  text-align: center;
  border: none;
  box-shadow: none;
}
.modal-content{
  border: none;
}

.modal-title {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
  padding: 1rem;
}
.modal-title {
  color: white;
  text-align: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-family: Helvetica, sans-serif;
  font-weight: 600;
}
.close {
  color: white;
  background: transparent;
  border: none;
}
#loginheader {
  padding-left: 42.5%;
}
.login-input {
  background: transparent;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.1rem;
  padding: 0.5rem 1rem;
  margin: 0.6rem;
  width: 65%;
  border-radius: 15px;
  border: 1px solid rgb(255, 255, 255, 0.5);
}

button .login-submit,.btn {
  background-color: wheat;
  color: white;
}

.sign-link a{
  color: #ffffff;
  font-size: 1.05rem;
  text-decoration: none;
}
.sign-link a:hover{
  text-decoration: underline;
  color: white;
  margin: 1rem;
}

.modal-body button{
  background-color: rgba(68, 65, 65, 0.808);
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  border-radius: 10px;
  border: none;
  margin: 1rem;
  padding: 0.5rem 3rem;
}
.toggle-eye{
  color: white;
  font-size: 1.1rem;
  margin-right: -20px;
  position: absolute;
  bottom: 8.5rem;
  right:8rem;
  opacity: 0.5;
}
.toggle-eye:hover{
  cursor: pointer;
}
.modal, .modal .modal-content{
  border: none;
}

.white{
  color: black;
  background-color: wheat;
  font-size: 1rem;
}

.msg-container{
  width: 100%;
  background-color: rgba(1, 1, 1, 0.95);


}
.failure{
  background-color: rgb(212, 90, 90);
  color: rgb(88, 6, 6);
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 700;
  font-size: 1.1rem;
  padding: 0.5rem;
  border: 2px solid rgb(88, 6, 6);
  border-radius:10px ;
  margin-top: 1.5rem;
  width: 80%;
  margin-left: 10%;
  text-align: center;
  }

  .success{
    background-color: rgb(101, 228, 101);
    color: rgb(8, 73, 8);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 700;
    font-size: 1.1rem;
    padding: 0.5rem;
    border: 2px solid rgb(8, 73, 8);
    border-radius:10px ;
    margin-top: 1.5rem;
    width: 80%;
    margin-left: 10%;
    text-align: center;
    }